.__header {
    width: 100%;
    transform: translate(0%, 0%) translateY(0);
    animation: 500ms ease 200ms 1 normal forwards running animatedHeader;
}

@keyframes animatedHeader {
    0% {
        opacity: 0;
        transform: translate(0%, 0%) translateY(-120px);
    }
    100% {
        opacity: 1;
        transform: translate(0%, 0%) translateY(0px);
    }
}

.__content_header {
    width: 90%;
    margin: auto;
    display: flex;
    padding: 1.5rem 0rem;
    align-items: center;
    justify-content: space-between;
}

.__content_header .__col_logo .__a_logo {
    display: grid;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    place-items: center;
}
.__content_header .__col_logo .__a_logo svg path {
    fill: var(--dark);
}

.__content_header .__col_menu {
    display: none;
}

.__content_header .__col_menu .__nav {
    display: flex;
    gap: 2rem;
}

.__content_header .__col_menu .__nav .__nav_li {
    position: relative;
}

.__content_header .__col_menu .__nav .__nav_li .__nav_li_a {
    font-weight: 500;
    padding: .8rem 1rem;
}

.__content_header .__col_subs .__btn_suscribe {
    font-weight: 500;
    padding: .8rem 1rem;
    border-radius: 10px;
    color: var(--light);
    background-color: var(--dark);
}

@media (min-width: 1024px) {

    .__content_header {
        width: 80%;
    }

    .__content_header .__col_menu {
        display: block;
    }

    .__content_header .__col_menu .__nav .__nav_li .__sub_nav {
        display: none;
        transition: .4s ease all;
        background-color: rgba(255, 255, 255, 1);
    }
    .__content_header .__col_menu .__nav .__nav_li:hover .__sub_nav {
        position: absolute;
        top: 30px;
        left: -45%;
        display: flex;
        width: 250px;
        gap: 1rem;
        padding: .5rem;
        border-radius: 10px;
        flex-direction: column;
        transition: .4s ease all;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 4px 4px 0px rgba(103, 103, 103, 0.2);
    }

    .__content_header .__col_menu .__nav .__nav_li:hover .__sub_nav .__sub_nav_li {
        width: 100%;
        padding: .5rem;
        border-radius: 5px;
    }

    .__content_header .__col_menu .__nav .__nav_li:hover .__sub_nav .__sub_nav_li:hover {
        background-color: #fafafa;
    }

    .__content_header .__col_menu .__nav .__nav_li:hover .__sub_nav .__sub_nav_li .__sub_nav_li_a {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .__content_header .__col_menu .__nav .__nav_li:hover .__sub_nav .__sub_nav_li .__sub_nav_li_a .__icio {
        display: grid;
        width: 40px;
        height: 40px;
        place-items: center;
        border-radius: 10px;
        background-color: #F2F2F2;
    }

}