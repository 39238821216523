.__footer {
    width: 100%;
    padding: 3rem 0rem;
}

.__content_footer {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.__content_footer .__row_footer_A {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.__content_footer .__row_footer_A .__col_A .__box_us {
    width: 40%;
}

.__content_footer .__row_footer_A .__col_A .__box_us .__log {
    width: 40px;
    height: 40px;
}

.__content_footer .__row_footer_A .__col_A .__box_us h2 {
    font-weight: 500;
    margin-bottom: .8rem;
    font-family: var(--font-family-playfair);
}

.__content_footer .__row_footer_A .__col_A .__box_us .__resum {
    font-size: .8rem;
    color: #888888;
    line-height: 28px;
    margin-bottom: .8rem;
}

.__content_footer .__row_footer_A .__col_A .__box_us .__ntw {
    display: flex;
    align-items: center;
}

.__content_footer .__row_footer_A .__col_A .__box_us .__ntw ul {
    display: flex;
}

.__content_footer .__row_footer_A .__col_A .__box_us .__ntw ul li a {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}

.__content_footer .__row_footer_A .__col_B {
    width: 80%;
}

.__content_footer .__row_footer_A .__col_B .__form {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--light);
}

.__content_footer .__row_footer_A .__col_B .__form .__txt_1 {
    font-size: 1.2rem;
    color: #888888;
}

.__content_footer .__row_footer_A .__col_B .__form .__txt_2 {
    font-size: .9rem;
}

.__content_footer .__row_footer_A .__col_B .__form .__form_group {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: .7rem 0rem;
}

.__content_footer .__row_footer_A .__col_B .__form .__form_group .__entry {
    width: 100%;
    height: 50px;
    padding: 0rem 1rem;
    border-radius: 10px;
    background-color: #EEEEEE;
}

.__content_footer .__row_footer_A .__col_B .__form .__form_group .__entry_error {
    border: 2px solid #FF0000;
}

.__content_footer .__row_footer_A .__col_B .__form .__form_group .__btn_send {
    padding: 0rem 1rem;
    color: var(--light);
    border-radius: 10px;
    background-color: #181818;
}

.__content_footer .__row_footer_A .__col_B .__form .__spam {
    font-size: .8rem;
    font-weight: 500;
    color: #888888;
}

.__content_footer .__row_footer_B {
    width: 100%;
    display: flex;
    padding: 1rem 0rem;
    align-items: center;
    justify-content: space-between;
}

.__content_footer .__row_footer_B .__col_A .__nav_min .__ul_nav_min {
    display: flex;
    gap: 2rem;
    padding: 0rem 1rem;
    align-items: center;
}

.__content_footer .__row_footer_B .__col_A .__nav_min .__ul_nav_min .__li_nav_min a {
    color: #181818;
    font-weight: 500;
}


@media (min-width: 1024px) {

    .__content_footer {
        width: 80%;
    }

}